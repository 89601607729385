import { component } from 'knockout-decorators'

import { EventItem } from '@tixa/schema'
import { Location } from '@tixa/schema'

import source from './item-place.html?raw'
import './item-place.less'

@component('item-place', source)
export class PlaceItem {
  private data: Location

  constructor(params: any) {
    this.data = params
  }

  public getTopEventsFormattedDate(event: EventItem): string {
    // return fecha.format(new Date(date), "MM.DD.");
    return event.customDate ? event.customDate : event.startDate
  }
}
